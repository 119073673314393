import { Component, Input, OnInit, ViewEncapsulation,Output, EventEmitter, SimpleChanges } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { NavigationEnd, Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { Capacitor } from '@capacitor/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SidebarComponent  implements OnInit {

  @Input() navigationUrl :any;
  @Input() loginUserDetail :any;
  @Input() isSkelton :any;
  @Output() collapsed: EventEmitter<any> = new EventEmitter();

  menuType: string = 'overlay';  
  public isCollapsed:boolean = false;
  public isNative:boolean = false;
  public activeMenuItem :string = 'home';
  public pageTitle :string = 'Home';
  public nevigationTopMenu: any = [];
  public nevigationBottomMenu: any = [];
  public bottomMenuOptions: any = [];
  public login_user_name: string = 'John Doe';
  public navType:string = '';
  public device:string = '';
  public nativeClass:string = 'web';
  public isDesktop = false;
  
  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly platform: Platform,
  ) { 
    this.getRouter();
    this.device = this.getDevive();
  }

   getRouter() {
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          let splitUrl = event.url.split('/');
          let url = (splitUrl[1]) ? splitUrl[1] : this.activeMenuItem; 
          this.activeMenuItem = url;
          this.loadMenuOption(this.activeMenuItem);
          if(Capacitor.isNativePlatform()) {
            this.isNative = true;
            this.nativeClass = 'native';
          }
        } 
      })
     
    }

    getDevive() {
      if ((this.platform.is('ios') || this.platform.is('iphone'))) {
        return "ios";
      }
      return this.platform.is('android') ? 'android' : 'web';
    }

    ngOnInit() {
        this.navType = environment.NAV_TYPE;
    }

    ngOnChanges(): void {
        this.loadMenuOption(this.navigationUrl);
    }

  loadMenuOption(navigationUrl:string){
    this.nevigationTopMenu = [
      {
        "iconName": "icon/home",
        "iconType":"svg",
        "displayName": "Home",
        "selected": this.menuselected(navigationUrl,'home'),
        "path": 'home' 
      },
      {
        "iconName": "icon/files",
        "displayName": "Documents",
        "iconType":"svg",
        "selected": this.menuselected(navigationUrl,'documents'),
        "path": 'documents' 
      },
      {
       "iconName": "icon/dollar-sign",
        "iconType":"svg",
        "displayName": "Payroll & expenses",
        "selected": this.menuselected(navigationUrl,'payroll-expenses'),
        "path": 'payroll-expenses'
      },
      {
       "iconName": "icon/user",
        "iconType":"svg",
        "displayName": "Profile",
        "selected": this.menuselected(navigationUrl,'profile'),
        "path": 'profile'
      },
      {
        "iconName": "icon/anchor",
        "iconType":"svg",
        "displayName": "Activities",
        "selected": this.menuselected(navigationUrl,'activities'),
        "path": 'activities'
      },
      {
        "iconName": "icon/volume",
        "iconType":"svg",
        "displayName": "News & announcements",
        "selected": this.menuselected(navigationUrl,'news-announcements')
      },
      {
        "iconName": "icon/messages",
        "iconType":"svg",
        "displayName": "360 feedback & survey",
        "selected": this.menuselected(navigationUrl,'feedback-survey')
      }
    ]

    const cashAccountObj = {
      iconName: "icon/money-check-dollar",
      iconType: "svg",
      displayName: "Cash Account Management",
      selected: this.menuselected(navigationUrl, 'cash-account-management'),
    };

    if (this.loginUserDetail?.role === "CPT") {
      this.nevigationTopMenu.splice(4, 0, cashAccountObj);
    }
    this.nevigationBottomMenu = [
      {
        "iconName": "icon/notification",
        "iconType":"svg",
        "displayName": "Notifications",
        "selected": this.menuselected(navigationUrl,'notifications'),
      },
      {
       "iconName": "icon/shield-check",
        "iconType":"svg",
        "displayName": "Privacy policy",
        "selected": this.menuselected(navigationUrl,'privacy-policy'),
      },
      {
        "iconName": "icon/monitor",
        "iconType":"svg",
        "displayName": "Open reporting",
        "selected": this.menuselected(navigationUrl,'open-reporting'),
      }
    ]

    this.bottomMenuOptions = [
      {
        "iconName": "icon/home",
        "iconType":"svg",
        "displayName": "Home",
        "selected": this.menuselected(navigationUrl,'home'),
        "path": 'home' 
      },
      {
       "iconName": "icon/files",
        "iconType":"svg",
        "displayName": "Documents",
        "selected": this.menuselected(navigationUrl,'documents'),
        "path": 'documents' 
      },
      {
        "iconName": "icon/dollar-sign",
        "iconType":"svg",
        "displayName": "Payroll & expenses",
        "selected": this.menuselected(navigationUrl,'payroll-expenses'),
        "path": 'payroll-expenses'
      },
      {
        "iconName": "icon/user",
        "iconType":"svg",
        "displayName": "Profile",
        "selected": this.menuselected(navigationUrl,'profile'),
        "path": 'profile'
      }
    ]
  }

  public menuselected(select:string,menu:string){
    if(select?.includes(menu)){
      return true;
    }
    return false;
  }

  navigationCollapse(){
    this.isCollapsed = !this.isCollapsed;
    this.collapsed.emit(this.isCollapsed);
  }

  public signOut() {  
    this.authService.signOut();
  }

  
  


 
  

}
